<template>
  <header class="about-you-header">
    <div class="about-you-header__userWidgetContainer">
      <page-title-bar class="about-you-header__pageTitleBar">
        {{ $t('aboutYou.pageTitle') }}
      </page-title-bar>

      <user-widget
        v-bind:modifiers="userWidgetModifiers.join(' ')"
        class="about-you-header__userWidget"
      />
    </div>

    <navigation-children v-bind:children="navigationChildren" />
  </header>
</template>

<script>
import PageTitleBar from '@/components/PageTitleBar';
import NavigationChildren from '@/components/NavigationChildren';
import UserWidget from '@/components/UserWidget';

export default {
  components: {
    PageTitleBar,
    NavigationChildren,
    UserWidget,
  },

  data() {
    return {
      userWidgetModifiers: ['large'],
      navigationChildren: [
        {
          to: '/overjou/voortgang',
          name: this.$t('navigation.aboutWorkouts'),
        },
        {
          to: '/overjou/lichaam',
          name: this.$t('navigation.aboutBody'),
        },
        {
          to: '/overjou/badges',
          name: this.$t('navigation.aboutBadges'),
        },
      ],
    };
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      if (window.innerWidth < 768) {
        if (this.userWidgetModifiers.indexOf('vertical') === -1) {
          this.userWidgetModifiers.push('vertical');
        }

        return;
      }

      if (this.userWidgetModifiers.indexOf('vertical') !== -1) {
        this.userWidgetModifiers.splice(this.userWidgetModifiers.indexOf('vertical'), 1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.about-you-header {
  @include bubbles;
  position: relative;
}

.about-you-header__userWidgetContainer {
  background-color: $color-beige;
  padding: 20px 1rem;

  @media (min-width: $breakpoint-md) {
    align-items: center;
    display: flex;
    padding: 2.5rem;
    justify-content: center;
  }
}

.about-you-header__pageTitleBar {
  margin: 0 0 16px 0;
}

.about-you-header__userWidget {
  max-width: 400px;
}
</style>
