<template>
  <div class="aboutYouPage">
    <base-section>
      <about-you-header class="aboutYouPage__header" />
    </base-section>

    <router-view />
  </div>
</template>

<script>
import BaseSection from '@/components/grid/BaseSection';
import AboutYouHeader from '@/components/aboutyou/AboutYouHeader';

export default {
  components: {
    BaseSection,
    AboutYouHeader,
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouPage__header {
  margin: 0 0 rem(16px) 0;

  @include desktop {
    margin: 0 0 rem(32px) 0;
  }
}
</style>