<template>
  <nav class="navigation-children">
    <div class="link-container">
      <component
        v-for="child in children"
        v-bind:key="child.id"
        v-bind:is="getComponent(child)"
        v-bind="getProps(child)"
        v-on:click="onClick($event, child)"
        class="router-link"
      >
        <span class="router-link__label">
          {{ child.name }}
        </span>
      </component>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    children: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getComponent(data) {
      return data.callback ? 'span' : 'router-link';
    },

    getProps(data) {
      if (data.callback) {
        return {};
      }

      return {
        to: data.to,
      };
    },

    onClick(event, data) {
      if (data.callback) {
        event.preventDefault();
        data.callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.router-link {
  cursor: pointer;

  &:not(:last-child):after {
    content: "•";
    margin: 0 12px;
    color: $color-pink;
  }

  &-active {
    @include small--bold;
  }
}

.router-link__label {
  position: relative;

  .router-link-active & {
    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $color-text;
    }
  }
}
</style>

<style lang="scss">
@import "@/scss/lib";

$navigation-children-height: 3rem;

.navigation-children {
  align-items: center;
  background-color: $color-white;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  height: $navigation-children-height;
  justify-content: center;
  position: relative;
  z-index: 1;

  .link-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 1rem;
    height: 100%;

    @media (min-width: $breakpoint-md) {
      padding: 0;
    }

    .router-link {
      @include small;
      color: $color-text;
      white-space: nowrap;
    }
  }
}
</style>
